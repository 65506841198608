<template>
  <div class="xtbInfo">
    <h1 style="font-size: 18px; text-align: center">协同宝协议</h1>
    <div class="xtbContent" id="license">
      <van-field
        v-model="message"
        rows="1"
        autosize
        label=""
        type="textarea"
        readonly
        placeholder="请输入留言"
      />
    </div>
    <van-button
      type="info"
      style="width: 100%; margin-top: 20px"
      @click="
        $router.push({ name: 'login', params: { is: true, ...$route.params } })
      "
      :disabled="disabled"
      >我已完成阅读</van-button
    >
  </div>
</template>

<script>
export default {
  name: "xtbInfo",
  components: {},
  data() {
    return {
      disabled: true,
      message: `【首部及导言】
欢迎您使用临港南大数智云。
为使用临港南大数智云服务(以下简称“本服务”)，您应当阅读并遵守《临港南大数智云服务协议》(以下简称“本协议”)。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。除非您已阅读并接受本协议所有条款，否则您无权使用临港南大数智云服务。您对本服务的登录、查看、发布信息等行为即视为您已阅读并同意本协议的约束。
一．【协议的范围】
1.1 本协议是您与益埃毕建筑科技有限公司(以下简称“益埃毕”)之间关于您使用临港南大数智云服务所订立的协议。 “用户”是指注册、登录、使用临港南大数智云帐号的个人或组织，在本协议中更多地称为“您”。
1.2 本服务是临港南大数智云向用户提供的信息管理、存储、发布、下载以及与此相关的互联网技术服务。临港南大数智云帐号可以通过临港南大数智云为相关用户提供服务，包括文件信息搜索、共享等。
1.3 您对本协议的接受即视为自愿接受全部条款的约束，包括接受临港南大数智云对任一服务条款随时所做的修改。临港南大数智云有权对本协议包含的条款随时更新，更新后的协议条款一旦公布即代替原来的协议条款，您可在本网站查阅最新版协议条款。在临港南大数智云修改本协议相关条款之后，若您不接受修改后的条款，请停止使用临港南大数智云提供的产品和服务，您继续使用临港南大数智云提供的产品和服务将被视为您已接受了修改后的协议。
二．【注册与账号】
2.1 临港南大数智云账号的所有权归益埃毕，您完成注册手续后，获得临港南大数智云账号的使用权。您应提供及时、详尽及真实的个人资料，并不断更新注册资料，符合及时、详尽及真实的要求。
2.2 您须对注册信息的真实性、合法性、有效性承担全部责任；不得冒充他人，不得利用他人的名义发布任何信息；不得恶意使用注册账号导致其他用户误认；否则临港南大数智云有权立即停止提供服务，由您独自承担由此而产生的一切法律责任。
2.3 您使用临港南大数智云产品、服务的行为必须合法，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何结果。您应对其中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。临港南大数智云无法且不会对因您行为而导致的任何损失或损害承担责任。
2.4 您须对自己的登录信息保密、不被其他人获取并使用并且对您在本网站账户下的所有行为负责。您必须将任何有可能触犯法律的，未授权使用或怀疑为未授权使用的行为在第一时间通知临港南大数智云，临港南大数智云不对您因未能遵守上述要求而造成的损失承担责任。
2.5 您不得将您的账号、密码转让、出售或出借予他人使用，若您授权他人使用账户，应对被授权人在该账号下发生所有行为负全部责任。
三．【服务内容】
3.1 临港南大数智云产品服务的具体内容由益埃毕根据实际情况提供。
3.2 除非本服务另有其它明示规定，临港南大数智云所推出的新产品、新功能、新服务，均受到本服务之规范。
3.3 鉴于网络服务的特殊性，您同意临港南大数智云有权不经事先通知，随时变更、中断或终止部分或全部的网络服务(包括收费网络服务)。临港南大数智云不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
3.4 临港南大数智云需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务(包括收费网络服务)在合理时间内的中断，临港南大数智云无需为此承担任何责任。临港南大数智云保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
3.5 本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于临港南大数智云无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，临港南大数智云不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，临港南大数智云亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，临港南大数智云不承担任何责任。
3.6 您明确同意其使用临港南大数智云产品和服务所存在的风险将完全由自己承担。您理解并接受下载或通过临港南大数智云服务取得的任何信息资料取决于您自己，并由您承担系统受损、资料丢失以及其它任何风险。临港南大数智云对在服务网上得到的任何商品购物服务、交易进程、招聘信息，都不作担保。
3.7 临港南大数智云有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论通知与否，临港南大数智云对您和任何第三人均无需承担任何责任。
四．【第三方产品和服务】
4.1 本服务可能包含第三方提供的产品或服务。当用户使用第三方提供的产品或服务时，可能会另有相关的协议或规则，您同样应当认真阅读并遵守。
4.2 您须理解并同意：为了向您提供更好的用户体验，当您在使用第三方服务时，第三方可能会接触、使用到您的昵称、头像以及在第三方服务中的相关操作信息等信息。
4.3 如您在使用第三方产品或服务时发生任何纠纷，请与第三方直接联系，临港南大数智云不承担任何责任，但根据需要会依法提供必要的协助。
五．【权利限制】
5.1 您不得对临港南大数智云的产品、程序及服务(包括但不限于内容或产品中的广告或赞助内容)进行任何形式的许可、出售、租赁、转让、发行或做其他商业用途；
5.2 您不得以创建相同或竞争服务为目的访问或使用临港南大数智云产品、程序及服务；
5.3 除非法律明文规定，否则您不得对临港南大数智云产品、程序及服务(包括但不限于内容或产品中的广告或赞助内容)的任何部分以任何形式或方法进行复制、发行、再版、下载、显示、张贴、修改、翻译、合并、利用、分解或反向编译等；
5.4 您在使用临港南大数智云产品和服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
​ 5.4.1 反对宪法所确定的基本原则的；
​ 5.4.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
​ 5.4.3 损害国家荣誉和利益的；
​ 5.4.4 煽动民族仇恨、民族歧视、破坏民族团结的；
​ 5.4.5 破坏国家宗教政策，宣扬邪教和封建迷信的；
​ 5.4.6 散布谣言，扰乱社会秩序，破坏社会稳定的；
​ 5.4.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
​ 5.4.8 侮辱或者诽谤他人，侵害他人合法权利的；
​ 5.4.9 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
​ 5.4.10 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
5.5 您已同意不在或不利用临港南大数智云产品、程序及服务从事下列行为：
​ 5.5.1 上传或发布电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；
​ 5.5.2 未授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；
​ 5.5.3 禁用本网站的网络连接，给本网站造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；
​ 5.5.4 在未授权的情况下，尝试访问本网站、服务器或本网站的网络链接；
​ 5.5.5 干扰、破坏其它用户正常使用临港南大数智云产品、程序及服务。
5.6 您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿益埃毕与合作公司、关联公司，并使之免受损害。对此，临港南大数智云有权视您的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收临港南大数智云账号、追究法律责任等措施。对恶意注册临港南大数智云账号或利用临港南大数智云账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，临港南大数智云有权回收其账号。同时，益埃毕公司会视司法部门的要求，协助调查。
5.7 您须对自己在使用临港南大数智云产品和服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在临港南大数智云首先承担了因您行为导致的行政处罚或侵权损害赔偿责任后，您应给予临港南大数智云等额的赔偿。
六．【风险与免责】
基于互联网的开放性和特殊性，临港南大数智云特别提醒您谨慎注意以下风险：
6.1 本服务仅提供一个账号管理的平台，您应当对其他用户使用本服务所产生的信息进行独立判断并承担因依赖该内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险以及因用户个人信息被其他用户获知而带来的风险。您了解并确认，临港南大数智云不对本服务用户之间的纠纷承担任何责任。
6.2 请您切勿在使用本服务时透露任何涉及财产、本服务账号及密码等重要信息，否则，由此带来的损失由您自行承担。
6.3 由于您或其他用户违反本服务相关规则导致被临港南大数智云依约处理的，可能会对您在本服务中的内容、信息等数据造成影响。
七．【不可抗力】
7.1 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，临港南大数智云将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失临港南大数智云在法律允许的范围内免责。
7.2 在法律允许的范围内，临港南大数智云对以下情形导致的服务中断或受阻不承担责任：
​ 7.2.1 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
​ 7.2.2 您或临港南大数智云的电脑软件、系统、硬件和通信线路出现故障；
​ 7.2.3 您操作不当；
​ 7.2.4 您通过非临港南大数智云授权的方式使用本服务；
​ 7.2.5 其他临港南大数智云无法控制或合理预见的情形。
八．【知识产权声明】
8.1 临港南大数智云在本服务中提供的内容(包括但不限于网页、文字、图片、音频、视频、图表等)的归原权利人所有，其中模型(.rvt文件)为临港南大数智云用户提供、分享，如用户使用模型进行商用行为，事先应该得到著作权人的授权或许可。临港南大数智云是一个收集、管理及信息展示的技术服务提供平台，平台为向所有用户提供更优质的服务，可能会对临港南大数智云帐号的昵称、头像、认证信息、公开群发信息等公开非保密内容在法律允许的范围内进行使用，包括但不限于提供搜索、链接等服务。
8.2 除另有特别声明外，临港南大数智云提供本服务时所依托软件的著作权、专利权及其他知识产权均归临港南大数智云所有。
8.3 临港南大数智云在本服务中所使用的“益埃毕”、“族库宝”、“基坑宝”“校园宝”“临港南大数智云”等商业标识，其商标权归临港南大数智云所有。
8.4 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经临港南大数智云、您或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
8.5 除前述规定以外，如果您认为有人复制并在临港南大数智云上发布您的作品，并已构成对您著作权的侵犯，请及时与我们联系，并提供包含如下信息的书面通知：
​ 8.5.1 证明您作为涉嫌侵权内容所有者的权属证明；
​ 8.5.2 明确的身份证明、住址、联系方式；
​ 8.5.3 涉嫌侵权内容在临港南大数智云上的位置；
​ 8.5.4 您声称遭受侵犯的著作权作品的描述；
​ 8.5.5 您著作权遭受侵犯的相关证明；
​ 8.5.6 在同意承担伪证处罚之后果的前提下，出具书面陈述以声明您在通知中所述内容是准确和真实的。
九．【服务的变更、中断、终止】
9.1 临港南大数智云可能会对服务内容进行变更，也可能会中断、中止或终止服务。
9.2 如发生下列任何一种情形，临港南大数智云有权不经通知而中断或终止向您提供的服务：
​ 9.2.1 根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
​ 9.2.2 您违反相关法律法规或本协议的约定；
​ 9.2.3 按照法律规定或主管部门的要求；
​ 9.2.4 出于安全的原因或其他必要的情形。
​ 9.2.5 根据约定您使用收费项目。若您未按时足额付费，临港南大数智云有权中断、中止或终止提供服务。
9.3 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，临港南大数智云可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，临港南大数智云没有义务向您返还数据。
十．【法律适用与管辖】
10.1 如果临港南大数智云发现或收到他人投诉用户违反本协议约定的，临港南大数智云有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、删除部分或全部关注用户、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。临港南大数智云也有权依照本协议及专项规则的规定，拒绝再向该主体提供服务。临港南大数智云认证帐号除上述处罚措施外，临港南大数智云有权取消其帐号认证身份，并视情节决定临时或永久封禁相关帐号认证资质。如果您发现任何人违反本协议规定或以其他不当的方式使用临港南大数智云服务，请立即向临港南大数智云投诉，我们将依法进行处理。
10.2 您理解并同意，临港南大数智云有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
10.3 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；临港南大数智云因此遭受损失的，您也应当一并赔偿。
10.4 您理解并同意，为及时保障用户合法权益不受侵害，若您的临港南大数智云帐号存在权益纠纷，临港南大数智云有权根据情况，采取包括但不限于暂停、冻结该临港南大数智云帐号的部分或全部功能等保护措施，直至有权机关作出生效裁判或用户在不违反本协议的前提下协商一致，并由此所产生的一切法律责任均与临港南大数智云无关。
10.5 本协议签订地为中华人民共和国北京市海淀区。
10.6 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律(不包括冲突法)。
10.7 若您和临港南大数智云之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
10.8 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
十一、【其它】
11.1 您使用本服务即视为您已阅读并同意受本协议的约束。临港南大数智云有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的条款。本协议条款变更后，如果您继续使用临港南大数智云服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用临港南大数智云服务。
11.2 请查阅我们的《隐私条款》，《隐私条款》为本协议效力不可分割的一部分，且与本协议具有同等法律效力。
11.3 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
11.4 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
`,
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
    let license = document.getElementById("license");
    let _this = this;
    license.onscroll = function() {
      if (
        license.scrollHeight - license.scrollTop <=
        license.clientHeight * 4
      ) {
        _this.disabled = false;
      } else {
        _this.disabled = true;
      }
    };
  },
};
</script>
<style lang="scss" scoped>
.xtbInfo {
  width: 100%;
  height: 100vh;
  padding: 30px;
  background-color: white;
  .xtbContent {
    height: 85%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
